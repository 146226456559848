import * as Api from '@/api'

const GET_PROJECTS = '/iop/projects/'
const GET_LTS = '/iop/lts/:lt'
const GET_LTS_HISTORY = '/iop/lts/:lt/history/'

const AD_GET_PROJECTS = '/ad/iop/projects/'
const AD_GET_LTS = '/ad/iop/lts/:lt'
const AD_GET_LTS_HISTORY = '/ad/iop/lts/:lt/history/'
const AD_GET_CLIENT = '/ad/iop/client/'

export default {
  getProjects (nif = false) {
    if (nif) {
      return Api.http(true).post(AD_GET_PROJECTS, { 'nifs': nif })
    }
    return Api.http(true).get(GET_PROJECTS)
  },
  getLts (lt, ad = false) {
    if (ad) {
      return Api.http(true).get(AD_GET_LTS.replace(':lt', lt))
    }
    return Api.http(true).get(GET_LTS.replace(':lt', lt))
  },
  getLtsHistory (lt, ad = false) {
    if (ad) {
      return Api.http(true).get(AD_GET_LTS_HISTORY.replace(':lt', lt))
    }
    return Api.http(true).get(GET_LTS_HISTORY.replace(':lt', lt))
  },
  getClient (name) {
    return Api.http(true).post(AD_GET_CLIENT, { 'client': name })
  }
}
