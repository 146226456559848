<template>
  <div id="contact">
    <b-row class="text-center">
      <b-col>
        <p class="contact-title">{{ contacts.title }}</p>
      </b-col>
    </b-row>
    <b-row class="contact-info">
      <b-col cols="1">
        <avatar backgroundColor="#0084D6" :src="contacts.src" :username="contacts.name"></avatar>
      </b-col>
      <b-col class="col-two-info">
        <b-row>
          <b-col>
            {{ contacts.name }}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            {{ contacts.phone }}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            {{ contacts.email }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Avatar from 'vue-avatar'

export default {
  name: 'Contact',
  props: {
    contacts: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    }
  },
  components: {
    Avatar
  }
}
</script>

<style lang="scss">
#contact {
  padding-bottom: 15px;
}
.col-1 {
  padding-right: 0px;
}
.contact-info {
  font-size: 11px;
  text-align: initial;
}
.contact-title {
  font-size: 1rem;
  text-align: initial;
  margin-bottom: 0;
  margin-top: 10px;
}
.avatar-icon {
  margin-right: 10px;
}
.col-two-info {
  padding-left: 35px !important;
  padding-right: 0px !important;
}
</style>
