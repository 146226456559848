<template>
  <div>
    <md-content class="md-elevation-4 md-alignment-center-center card-altice">
      <div v-if="icon !== 'ok'" class="middle-card">
        <md-icon class="md-size-5x">{{ icon }}</md-icon>
      </div>
      <div  v-if="icon === 'ok'" class="middle-card">
        <div class="swal2-icon swal2-success">
          <div class="swal2-success-circular-line-left"></div>
          <span class="swal2-success-line-tip"></span> <span class="swal2-success-line-long"></span>
          <div class="swal2-success-ring"></div> <div class="swal2-success-fix"></div>
          <div class="swal2-success-circular-line-right"></div>
        </div>
      </div>
      <div>
        <span class="md-title">{{ text }}</span>
      </div>
      <div>
        <span v-if="subTitle" class="md-subheading">{{ subTitle }}</span>
      </div>
    </md-content>
  </div>
</template>

<script>
export default {
  name: 'card-dialog',
  props: ['text', 'subTitle', 'icon'],
  mounted () {
    const element = this.$el.querySelector('.card-altice')
    element.classList.add('animated', 'bounceIn')
  }
}
</script>

<style lang="scss">
.survey {
  margin: 10px;
}

.middle-card {
  padding-top: 0vh;
}

.swal2-popup{
  border-radius:0px;
}

.swal2-html-container{
  font-size:14px!important;
  color: black !important;
}

.swal2-confirm{
  border: 2px solid #333!important;
  padding: 10px 25px!important;
  font-size: 10px!important;
  letter-spacing: 0.12em!important;
  font-weight: 700!important;
  height: 40px!important;
  background-color: #333 !important;
  color: white !important;
  border-radius: 5px!important;
  margin:0px 10px;
  text-transform: uppercase!important;
}

.swal2-cancel{
  border: 2px solid black!important;
  padding: 10px 25px!important;
  font-size: 10px!important;
  letter-spacing: 0.12em!important;
  font-weight: 700!important;
  height: 40px!important;
  background-color: transparent !important;
  color: black !important;
  border-radius: 5px!important;
  text-transform: uppercase!important;
  margin:0px 10px;
}

.swal2-confirm:hover{
  border: 2px solid #0084D6!important;
  background-color: #0084D6 !important;
  color: white !important;
}

.swal2-cancel:hover{
  border: 2px solid #0084D6!important;
  background-color: #0084D6 !important;
  color: white !important;
}

.swal2-warning{
  border-color:#0084D6!important;
  color:#0084D6!important;
}

.swal2-success-ring{
  border-color:#0084D6!important;
  color:#0084D6!important;
}

.swal2-success-ring{
  border-color:#0084D6!important;
  color:#0084D6!important;
}

.swal2-success-fix{
  color:#0084D6!important;
}

.swal2-success-line-tip{
  background-color:#0084D6!important;
}

.swal2-success-line-long{
  background-color:#0084D6!important;
}

.swal2-title{
  color:black!important;
  font-size:20px!important;
}

.swal2-progress-step-line{
  background-color: #ebebeb!important;
}

.swal2-progress-step{
  background-color: #ebebeb!important;
}

.swal2-active-progress-step{
  background-color: #0084D6!important;
}

.swal2-input:focus{
  border: 1px solid #0084D6;
  box-shadow: 0 0 3px #ebebeb;
}

.swal2-input{
  font-size:14px;
}

.swal2-textarea:focus{
  border: 1px solid #0084D6;
  box-shadow: 0 0 3px #ebebeb;
}

.swal2-textarea{
  font-size:14px;
}

</style>
